const propertyData = {
    "url": "http://10046NorthOswegoAvenue.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "10046 North Oswego Avenue", "city": "Portland", "state":"Oregon", "zipcode": "97203"},
    "display_address": "10046 North Oswego Avenue",
    "property": {
      "listing_price": "$414,900",
      "bedrooms": "3",
      "baths": "1",
      "sq_feet": "2208",
      "lot_size": "4791.6",
      "rmls": "20590439",
      "built_in": "1912",
      "blurb": "Beautiful St. Johns home loaded with charm & character! Built in 1912, open floor plan and many updates. Large kitchen with granite counters, ample cabinets, stainless appliances, gas range & eating bar. Master bedroom w/ built-in cabinets. High efficiency furnace, central AC & seismic updating. Relax on private back wrap around deck and enjoy the large family friendly fenced back yard including fire pit, stone paths, fruit trees & garden. Conveniently located to shopping & restaurants.",
      "ammenities": [

      ]
    },
    "image_count": 21,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2791.6342579352845!2d-122.74701998443803!3d45.597896679102746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5495a80f098bf621%3A0x62c664f181a7e366!2s10046%20N%20Oswego%20Ave%2C%20Portland%2C%20OR%2097203!5e0!3m2!1sen!2sus!4v1593036218802!5m2!1sen!2sus",
        "youtube":"qP6Ii24TOaA"
    }
  }

export default propertyData;

